import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    navbar: {
      display: "flex",
      "@media (max-width: 768px)": {
        height: "75px",
      },
      "& button": {
        "@media (min-width: 769px)": {
          display: "none",
        },
        "& svg": {
          height: "3em",
          width: "3em",
        },
      },
      "& .MuiTabs-root": {
        "@media (max-width: 768px)": {
          display: "none",
        },
        "& .MuiTab-wrapper": {
          fontSize: "1.5em",
        },
      },
    },
    contactForm: {
      width: "95%",
      maxWidth: "800px",
      margin: "0px auto",
      "& .MuiPaper-root": {
        display: "flex",
        flexDirection: "column",
        padding: "2em",
        marginTop: "25px",
        "& .MuiFormControl-root": {
          width: "90%",
          maxWidth: "500px",
          margin: "0px auto",
        },
        "& .MuiButtonBase-root": {
          margin: "15px auto 0px auto",
        },
      },
    },
    contactStatus: {
      padding: "2em",
      textAlign: "center",
    },
    portfolioCard: {
      padding: ".5em",
      "& .MuiCardContent-root": {
        height: "150px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        "& .MuiTypography-subtitle1, .MuiTypography-subtitle2": {
          height: "75px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        "& .MuiGrid-root": {
          "& .MuiGrid-item": {
            display: "flex",
            justifyContent: "center",
          },
          "& > button": {
            height: "2.5em",
            width: "2.5em",
          },
        },
      },
      "& .MuiCardMedia-root": {
        height: "180px",
      },
      "& .MuiGrid-root": {
        marginBottom: "5px",
      },
    },
    skillList: {
      width: "90%",
      maxWidth: "900px",
      margin: "0px auto",
      "& .MuiListItem-gutters": {
        paddingLeft: "0px",
        paddingRight: "0px",
      },
      "& .MuiListItem-root .MuiPaper-root": {
        width: "100%",
        padding: ".5em",
      },
    },
    portfolio: {
      marginBottom: "25px",
      "& > h2": {
        marginBottom: "25px",
      },
    },
    socials: {
      margin: "20px 0px",
      "& .MuiPaper-root": {
        width: "95%",
        maxWidth: "500px",
        padding: "1em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .MuiGrid-root:nth-child(2)": {
          width: "60%",
          marginTop: "10px",
          display: "flex",
          justifyContent: "space-around",
        },
      },
    },
    drawer: {
      "& .MuiDrawer-paper": {
        width: "50%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& .MuiContainer-root": {
          width: "50%",
          height: "40%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      },
    },
    footer: {
      width: "100%",
      padding: ".25em 0",
      position: "fixed",
      bottom: "0px",
      overflow: "hidden",
      "& .MuiPaper-root": {
        zIndex: "0",
        "& .MuiGrid-container": {
          paddingTop: ".5em",
          "& .MuiGrid-item": {
            "@media (max-width:600px)": {
              "&:nth-child(2)": {
                display: "none",
              },
            },
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            "& a": {
              height: "25px !important",
              width: "25px !important",
              margin: "0px 5px",
            },
          },
        },
      },
    },
    Accordion: {
      display: "flex",
      flexDirection: "column",
      "& ul": {
        paddingLeft: "15px",
      },
    },
  })
);

export default useStyles;
