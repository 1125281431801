import { createMuiTheme } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";

const defaultTheme = {
  spacing: 2,
  palette: {
    primary: {
      main: blue[800],
    },
    secondary: {
      main: "#000000",
    },
  },
  typography: {
    h1: {
      fontSize: "3rem",
      marginTop: "20px",
      "@media (max-width: 600px)": {
        fontSize: "2rem",
      },
    },
    h2: {
      fontSize: "2.25rem",
      margin: "15px 0px",
      "@media (max-width: 600px)": {
        fontSize: "1.85rem",
      },
    },
    h3: {
      fontSize: "1.65rem",
      margin: "15px 0px",
      "@media (max-width: 600px)": {
        fontSize: "1.25rem",
      },
    },
    subtitle1: {
      fontSize: "1.25rem",
      "@media (max-width: 600px)": {
        fontSize: ".85rem",
      },
    },
    body1: {
      "@media (max-width: 600px)": {
        fontSize: "90%",
      },
    },
    body2: {
      "@media (max-width: 600px)": {
        fontSize: "80%",
      },
    },
  },
};

const darkTheme = {
  ...defaultTheme,
  palette: {
    type: "dark",
    primary: {
      main: blue[500],
    },
    secondary: {
      main: "#ffffff",
    },
  },
};

const makeTheme = (isDarkMode) => {
  return createMuiTheme(isDarkMode ? darkTheme : defaultTheme);
};

export default makeTheme;
