import {
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import {
  DiWordpress,
  DiPhp,
  DiHtml5,
  DiCss3,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiRedis,
} from "react-icons/di";
import { SiMaterialUi, SiSocketDotIo } from "react-icons/si";

import useStyles from "../Styles/useStyles";

const supportedTech = {
  html5: "HTML5",
  css3: "CSS3",
  wordpress: "WordPress",
  php: "PHP",
  nodejs: "Node.js",
  mongodb: "MongoDB",
  react: "React",
  materialui: "Material UI",
  "socket.io": "Socket.io",
  redis: "Redis",
};

const PortfolioCard = (props) => {
  const classes = useStyles();

  const renderLogo = (tech) => {
    switch (tech) {
      case "wordpress":
        return (
          <IconButton>
            <DiWordpress />
          </IconButton>
        );
      case "php":
        return (
          <IconButton>
            <DiPhp />
          </IconButton>
        );
      case "css3":
        return (
          <IconButton>
            <DiCss3 />
          </IconButton>
        );
      case "html5":
        return (
          <IconButton>
            <DiHtml5 />
          </IconButton>
        );
      case "react":
        return (
          <IconButton>
            <DiReact />
          </IconButton>
        );
      case "nodejs":
        return (
          <IconButton>
            <DiNodejs />
          </IconButton>
        );
      case "mongodb":
        return (
          <IconButton>
            <DiMongodb />
          </IconButton>
        );
      case "redis":
        return (
          <IconButton>
            <DiRedis />
          </IconButton>
        );
      case "materialui":
        return (
          <IconButton>
            <SiMaterialUi />
          </IconButton>
        );
      case "socket.io":
        return (
          <IconButton>
            <SiSocketDotIo />
          </IconButton>
        );
      default:
        return tech;
    }
  };

  return (
    <Grid item xs={10} sm={8} md={6} lg={4}>
      <Card className={classes.portfolioCard}>
        <CardMedia title={props.title} image={props.image} />
        <CardContent>
          <Typography variant="subtitle1">{props.title}</Typography>
          <Typography variant="subtitle2">
            {!props.collaboration ? "Solo" : "Collaboration"}
          </Typography>
          <Grid container justify="center">
            {props.tech &&
              props.tech.map((tech) => {
                return (
                  <Grid key={tech} item xs={2}>
                    <Tooltip title={supportedTech[tech]}>
                      {renderLogo(tech)}
                    </Tooltip>
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
        <Grid container justify="space-around">
          {props.url && (
            <Grid item>
              <Button
                component="a"
                variant="outlined"
                href={props.url}
                target="_blank"
              >
                View Site
              </Button>
            </Grid>
          )}
          {props.github && (
            <Grid item>
              <Button
                component="a"
                variant="outlined"
                href={props.github}
                target="_blank"
              >
                View Code
              </Button>
            </Grid>
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default PortfolioCard;
