import { Typography, Container } from "@material-ui/core";

import "./Homepage.css";

const Homepage = () => {
  return (
    <Container maxWidth="lg">
      <Typography variant="h1">Welcome to NathWebDev.com!</Typography>
      <Typography variant="subtitle1">
        This site was created with React and numerous packages. If you are
        interested to read more into the development, check out the about page.
      </Typography>
      <br />
      <Typography variant="subtitle1">
        I plan to continue developing this site as my skillset increases so be
        sure to check back soon for my latest projects and any changes I have
        made since your last visit.
      </Typography>
    </Container>
  );
};

export default Homepage;
