import { Container, Typography, Grid } from "@material-ui/core";

import PortfolioCard from "../Components/PortfolioCard/PortfolioCard";
import useStyles from "../Components/Styles/useStyles";

import BarlingsBeach from "../Assets/BarlingsBeach.PNG";
import SpecialCakes from "../Assets/SpecialCakes.PNG";
import RedisNode from "../Assets/RedisNode.PNG";
import SocialMedia from "../Assets/SocialMedia.PNG";
import VideoChat from "../Assets/VideoChat.png";

const Portfolio = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg" className={classes.portfolio}>
      <Typography variant="h2">My Portfolio</Typography>
      <Grid container justify="space-around" spacing={8}>
        <PortfolioCard
          image={BarlingsBeach}
          title="Barlings Beach Holiday Escapes"
          tech={["css3", "html5", "wordpress", "php"]}
          url="https://dev-barlingsbeachhe.pantheonsite.io/"
        />
        <PortfolioCard
          image={SpecialCakes}
          title="Special Cakes for All Occassions"
          tech={["css3", "html5", "wordpress", "php"]}
          url="https://dev-nathancakes.pantheonsite.io/"
        />
        {/* <PortfolioCard
          title="Social Media Clone"
          tech={["nodejs", "mongodb", "react", "materialui"]}
          image={SocialMedia}
          github="https://github.com/Creeds1996/socialmedia"
        /> */}
        <PortfolioCard
          title="Video Chat Application"
          tech={["nodejs", "socket.io", "react", "materialui"]}
          image={VideoChat}
          url="https://nathanwebrtc.netlify.app/"
          github="https://github.com/Creeds1996/ChatApp/tree/main"
        />
        <PortfolioCard
          title="Github API w/ Redis"
          tech={["nodejs", "react", "redis", "materialui"]}
          image={RedisNode}
          url="https://d9vtm39z.herokuapp.com/"
          github="https://github.com/jnlar/github-api-redis"
          collaboration
        />
      </Grid>
    </Container>
  );
};

export default Portfolio;
