import { useState } from "react";
import {
  Tabs,
  Tab,
  Button,
  SwipeableDrawer,
  Typography,
  Container,
} from "@material-ui/core";
import { AiOutlineMenu } from "react-icons/ai";
import { NavLink } from "react-router-dom";

import useStyles from "../Styles/useStyles";

const Navbar = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const classes = useStyles();

  return (
    <header className={classes.navbar}>
      <Button onClick={() => setIsDrawerOpen(true)}>
        <AiOutlineMenu />
      </Button>
      <SwipeableDrawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onOpen={() => setIsDrawerOpen(true)}
        className={classes.drawer}
      >
        <Container>
          <Button
            component={NavLink}
            to="/"
            onClick={() => setIsDrawerOpen(false)}
          >
            <Typography variant="subtitle1">Home</Typography>
          </Button>
          <Button
            component={NavLink}
            to="/about"
            onClick={() => setIsDrawerOpen(false)}
          >
            <Typography variant="subtitle1">About</Typography>
          </Button>
          <Button
            component={NavLink}
            to="/portfolio"
            onClick={() => setIsDrawerOpen(false)}
          >
            <Typography variant="subtitle1">Portfolio</Typography>
          </Button>
          <Button
            component={NavLink}
            to="/contact"
            onClick={() => setIsDrawerOpen(false)}
          >
            <Typography variant="subtitle1">Contact</Typography>
          </Button>
        </Container>
      </SwipeableDrawer>
      <Tabs value={props.history.location.pathname}>
        <Tab component={NavLink} to="/" label="Home" value="/" />
        <Tab component={NavLink} to="/about" label="About" value="/about" />
        <Tab
          component={NavLink}
          to="/portfolio"
          label="Portfolio"
          value="/portfolio"
        />
        <Tab
          component={NavLink}
          to="/contact"
          label="Contact"
          value="/contact"
        />
      </Tabs>
    </header>
  );
};

export default Navbar;
