import { useContext, Fragment } from "react";
import { Switch } from "@material-ui/core";
import Brightness5Icon from "@material-ui/icons/Brightness5";
import Brightness2Icon from "@material-ui/icons/Brightness2";

import { ThemeContext } from "../../Context/ThemeContext";

const DarkModeToggle = (props) => {
  const { toggleDarkTheme } = useContext(ThemeContext);

  return (
    <Fragment>
      <Brightness5Icon />
      <Switch onChange={toggleDarkTheme} />
      <Brightness2Icon />
    </Fragment>
  );
};

export default DarkModeToggle;
