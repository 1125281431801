import {
  ListItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useStyles from "../../Styles/useStyles";

const Skill = ({ title, children }) => {
  const classes = useStyles();

  return (
    <ListItem>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle1">{title}</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.Accordion}>
          {children}
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
};

export default Skill;
