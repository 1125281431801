import { Container, Typography } from "@material-ui/core";

import ContactForm from "../Components/Forms/ContactForm";
import Socials from "../Components/Socials/Socials";

const Contact = () => {
  return (
    <Container>
      <Typography variant="h2">Want to Contact Me?</Typography>
      <Typography variant="subtitle1">
        I have provided an easy to use contact form below. Once submitted, all
        the content entered will be forwarded to my email and I'll be sure to
        reply as soon as I can!
      </Typography>
      <ContactForm />
      <Socials />
    </Container>
  );
};

export default Contact;
