import { useContext } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { ThemeProvider, CssBaseline } from "@material-ui/core";

import Navbar from "./Components/Navigation/Navbar";
import Homepage from "./Pages/Homepage";
import About from "./Pages/About";
import Portfolio from "./Pages/Portfolio";
import Contact from "./Pages/Contact";
import Footer from "./Components/Footer/Footer";
import makeTheme from "./Components/Theme/Theme";
import { ThemeContext } from "./Context/ThemeContext";

import "./App.css";

function App() {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <ThemeProvider theme={makeTheme(isDarkMode)}>
      <CssBaseline />
      <Router>
        <Route
          render={({ history }) => {
            return (
              <div className="App">
                <Navbar history={history} />
                <Switch>
                  <Route exact path="/">
                    <Homepage />
                  </Route>
                  <Route path="/about">
                    <About />
                  </Route>
                  <Route path="/portfolio">
                    <Portfolio />
                  </Route>
                  <Route path="/contact">
                    <Contact />
                  </Route>
                </Switch>
                <Footer />
              </div>
            );
          }}
        />
      </Router>
    </ThemeProvider>
  );
}

export default App;
