import { List, ListItem, Typography } from "@material-ui/core";

import Skill from "./Skill/Skill";
import useStyles from "../Styles/useStyles";

const Skills = () => {
  const classes = useStyles();

  return (
    <List className={classes.skillList}>
      <Skill title="HTML5">
        <Typography variant="body1">
          I feel extremely confident when working with HTML.
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body2">Audio / Video</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              Page Structure (Header, Body, Footer etc)
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Figure and FigCaption</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Local / Session Storage</Typography>
          </ListItem>
        </List>
      </Skill>
      <Skill title="CSS3">
        <Typography variant="body1">
          I feel extremely confident when working with CSS.
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body2">Box Model</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Animations/Transitions</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              Selectors, Pseudo Classes and Functions
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Flexbox</Typography>
          </ListItem>
        </List>
      </Skill>
      <Skill title="JavaScript">
        <Typography variant="body1">
          I feel extremely confident when working with JavaScript.
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body2">ECMAScript 6 (ES6)</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Asyncronous Programming</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">jQuery</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Fetch API</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Try / Catch Blocks</Typography>
          </ListItem>
        </List>
      </Skill>
      <Skill title="PHP">
        <Typography variant="body1">
          I feel extremely confident when working with PHP.
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body2">MySQLi</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">GET / POST</Typography>
          </ListItem>
        </List>
      </Skill>
      <Skill title="SQL">
        <Typography variant="body1">
          I feel extremely confident when working with SQL.
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body2">
              Create, Alter, Drop and Truncate tables.
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Foreign and Primary Keys.</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Insert into tables.</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Queries.</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Procedures.</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Triggers.</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Joins.</Typography>
          </ListItem>
        </List>
      </Skill>
    </List>
  );
};

export default Skills;
