import {
  Container,
  Grid,
  List,
  ListItem,
  Typography,
  Link,
} from "@material-ui/core";

import Skills from "../Components/Skills/Skills";
import Libraries from "../Components/Skills/Libraries";

const About = () => {
  return (
    <Container>
      <Grid container justify="center" spacing={6}>
        <Grid item xs={11}>
          <Typography variant="h2">Who am I?</Typography>
          <Typography variant="body1">
            Hi, my name is Nathan. I've loved all things computers for as long
            as I can remember.
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h2">My Skills</Typography>
          <Typography variant="h3">Programming Languages</Typography>
          <Skills />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h3">Libraries</Typography>
          <Libraries />
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h2">What will you find on this site?</Typography>
          <Typography variant="body1">
            This site was created as a showcase for my front end web development
            skills. It contains numerous projects that I have either completed
            or find myself currently working on.
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography variant="h2">
            What technology was used to make this website?
          </Typography>
          <Typography variant="body1">
            This website was developed with the use of <b>React</b> and numerous
            NPM packages to achieve additional functionality. The original
            project was created with the create-react-app command, so it came
            with pre-installed dependencies such as React, React DOM, React
            Scripts, Web Vitals and Jest.
          </Typography>
          <Typography variant="h3">Additional Packages:</Typography>
          <List>
            <ListItem>
              <Link
                variant="body1"
                href="https://www.npmjs.com/package/react-icons"
                target="_blank"
              >
                React Icons
              </Link>
            </ListItem>
            <ListItem>
              <Link
                variant="body1"
                href="https://www.npmjs.com/package/react-router-dom"
                target="_blank"
              >
                React Router DOM
              </Link>
            </ListItem>
            <ListItem>
              <Link
                variant="body1"
                href="https://www.npmjs.com/package/react-social-icons"
                target="_blank"
              >
                React Social Icons
              </Link>
            </ListItem>
            <ListItem>
              <Link
                variant="body1"
                href="https://www.npmjs.com/package/@material-ui/core"
                target="_blank"
              >
                Material UI/Core
              </Link>
            </ListItem>
            <ListItem>
              <Link
                variant="body1"
                href="https://www.npmjs.com/package/@material-ui/icons"
                target="_blank"
              >
                Material UI/Icons
              </Link>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export default About;
