import { Grid, Divider, Typography, Paper } from "@material-ui/core";
import { SocialIcon } from "react-social-icons";

import DarkModeToggle from "../DarkModeToggle/DarkModeToggle";
import useStyles from "../Styles/useStyles";

const Footer = (props) => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Paper>
        <Divider />
        <Grid container justify="center" spacing={4}>
          <Grid item xs={6} sm={4}>
            <DarkModeToggle />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Typography variant="caption">Created by Nathan</Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <SocialIcon
              network="twitter"
              target="_blank"
              rel="noopener"
              url="https://twitter.com/NathWebDev"
            />
            <SocialIcon
              network="linkedin"
              target="_blank"
              rel="noopener"
              url="https://www.linkedin.com/in/nathan-williams-340586163/"
            />
            <SocialIcon
              network="github"
              target="_blank"
              rel="noopener"
              url="https://github.com/Creeds1996"
            />
          </Grid>
        </Grid>
      </Paper>
    </footer>
  );
};

export default Footer;
