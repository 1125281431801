import { List, ListItem, Typography } from "@material-ui/core";

import Skill from "./Skill/Skill";
import useStyles from "../Styles/useStyles";

const Libraries = () => {
  const classes = useStyles();

  return (
    <List className={classes.skillList}>
      <Skill title="React">
        <Typography variant="body1">
          I feel extremely confident when working with React.
        </Typography>
        <List>
          <ListItem>
            <Typography variant="body2">Components and Props</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              Functional / Class Components
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              Component Lifecycle (Mounting, Updating, Unmounting).
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Lifecycle Hooks (useEffect)</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">
              State (setState and useState)
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Portals - React DOM</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Context (useContext)</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Reducer (useReducer)</Typography>
          </ListItem>
        </List>
      </Skill>
      <Skill title="Redux">
        <Typography variant="body1">
          I feel confident when working with Redux.
        </Typography>
        <List></List>
      </Skill>
      <Skill title="Express">
        <Typography variant="body1">
          I feel confident when working with Express.
        </Typography>
        <List></List>
      </Skill>
    </List>
  );
};

export default Libraries;
