import { SocialIcon } from "react-social-icons";
import { Paper, Grid, Typography } from "@material-ui/core";

import useStyles from "../Styles/useStyles";

const Socials = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={classes.socials}
      justify="center"
      alignItems="center"
    >
      <Paper>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Feel free to reach me on my socials!
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <SocialIcon
            network="twitter"
            target="_blank"
            rel="noopener"
            url="https://twitter.com/NathWebDev"
          />
          <SocialIcon
            network="linkedin"
            target="_blank"
            rel="noopener"
            url="https://www.linkedin.com/in/nathan-williams-340586163/"
          />
          <SocialIcon
            network="github"
            target="_blank"
            rel="noopener"
            url="https://github.com/Creeds1996"
          />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Socials;
