import { useState } from "react";
import axios from "axios";
import {
  TextField,
  Button,
  Paper,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { isValidString, isEmail } from "../../Util/Validation";
import useStyles from "../Styles/useStyles";

const ContactForm = () => {
  const classes = useStyles();

  const defaultState = {
    name: {
      value: "",
      valid: false,
      touched: false,
    },
    email: {
      value: "",
      valid: false,
      touched: false,
    },
    message: {
      value: "",
      valid: false,
      touched: false,
    },
  };

  const [userInput, setUserInput] = useState({ ...defaultState });
  const [emailState, setEmailState] = useState(null);

  const onSubmitHandler = (event) => {
    event.preventDefault();

    setEmailState("Sending");

    axios({
      method: "POST",
      url: "https://webbackendnathan.herokuapp.com/contact/email",
      data: {
        name: event.target.name.value,
        email: event.target.email.value,
        message: event.target.message.value,
      },
    })
      .then((response) => {
        setEmailState("Sent");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onChangeHandler = (event, validation) => {
    setUserInput({
      ...userInput,
      [event.target.id]: {
        ...userInput[event.target.id],
        value: event.target.value,
        valid: validation(event.target.value),
      },
    });
  };

  const onBlurHandler = (event) => {
    setUserInput({
      ...userInput,
      [event.target.id]: {
        ...userInput[event.target.id],
        touched: true,
      },
    });
  };

  return !emailState ? (
    <form
      className={classes.contactForm}
      onSubmit={(event) => onSubmitHandler(event)}
    >
      <Paper>
        <TextField
          id="name"
          label="Name"
          onChange={(event) => onChangeHandler(event, isValidString)}
          onBlur={onBlurHandler}
          value={userInput.name.value}
          error={!userInput.name.valid && userInput.name.touched}
          helperText="Please enter your name."
        />
        <TextField
          id="email"
          label="Email"
          onChange={(event) => onChangeHandler(event, isEmail)}
          onBlur={onBlurHandler}
          value={userInput.email.value}
          error={!userInput.email.valid && userInput.email.touched}
          helperText="Please enter your email."
        />
        <TextField
          id="message"
          label="Message"
          onChange={(event) => onChangeHandler(event, isValidString)}
          onBlur={onBlurHandler}
          value={userInput.message.value}
          error={!userInput.message.valid && userInput.message.touched}
          helperText="Please enter a message."
        />
        <Button
          type="submit"
          disabled={
            !userInput.name.valid ||
            !userInput.email.valid ||
            !userInput.message.valid
          }
          variant="outlined"
        >
          Submit
        </Button>
      </Paper>
    </form>
  ) : (
    <Paper className={classes.contactStatus}>
      {emailState === "Sending" ? (
        <CircularProgress size="5em" />
      ) : (
        <Typography variant="h3">
          Thanks for getting in touch! I'll be sure to reply as soon as I can!
        </Typography>
      )}
    </Paper>
  );
};

export default ContactForm;
