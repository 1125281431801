import { createContext, useState } from "react";

export const ThemeContext = createContext();

export const ThemeContextHOC = (props) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleDarkTheme }}>
      {props.children}
    </ThemeContext.Provider>
  );
};
